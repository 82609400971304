import { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import client from 'api/apollo-client';
import ConditionalLink from 'components/conditional-link/conditional-link';
import { useAppSelector } from 'redux/store';
import { getServerPageGetNotificationBar } from 'api/graphql-helper';
import { NotificationBarFieldsFragment } from 'api/graphql-types';
import { Icon } from 'components/icon/icon';
import { STYLES } from 'styles/style-variables';

import IconHorn from 'public/assets/icons/horn.svg';

import styles from './navbar.module.scss';

const NotificationBar = (): ReactElement | null => {
	const router = useRouter();

	const { hideSearchbar } = useAppSelector((state) => state.settings);

	const [notification, setNotification] = useState<NotificationBarFieldsFragment | undefined>(undefined);

	const getNotificationData = async (): Promise<void> => {
		const result = await (
			await getServerPageGetNotificationBar({ variables: { locale: router.locale } }, client)
		)?.props?.data?.pageInformation?.data?.attributes?.navbar;

		if (result) {
			setNotification(result);
		}
	};

	const convertToValidDate = (date?: string | Date): Date | undefined => {
		// Check if is valid date
		if (date && !Number.isNaN(new Date(date).getDay())) {
			return new Date(date);
		}

		return undefined;
	};

	const publishNotificationMessage = (date?: Date): boolean => {
		const publishDate = convertToValidDate(date);

		if (publishDate) {
			const currentDate = new Date();
			const unpublishDate = convertToValidDate(notification?.notificationUnpublishDate);

			if (unpublishDate) {
				return Boolean(notification?.notificationMessage && publishDate <= currentDate && currentDate < unpublishDate);
			}

			return Boolean(notification?.notificationMessage && publishDate <= currentDate);
		}

		return Boolean(notification?.notificationMessage);
	};

	useEffect(() => {
		getNotificationData();
	}, []);

	if (!notification) return <></>;

	return (
		<div
			style={{
				height: publishNotificationMessage(notification?.notificationPublishDate) ? 'auto' : '0px',
				padding: publishNotificationMessage(notification?.notificationPublishDate) ? '12px 0' : '0px',
				visibility: publishNotificationMessage(notification?.notificationPublishDate) ? 'visible' : 'hidden'
			}}
			className={clsx(
				styles.messageBar,
				notification.notificationLink && styles.hasLink,
				!notification?.mostPopularTitle &&
					!notification?.ThemeGroupTitle &&
					!notification?.bottomLinks &&
					styles.messageBarShiftUp,
				hideSearchbar && styles.noSearchbar
			)}
		>
			<ConditionalLink
				href={notification.notificationLink?.url}
				condition={
					notification.notificationLink?.url && publishNotificationMessage(notification?.notificationPublishDate)
				}
				passHref
			>
				<>
					<span className={styles.icon}>
						<Icon customIcon={<IconHorn />} size="14px" forceColor={STYLES.COLOR_PRIMARY_BLUE} />
					</span>
					<span className={styles.messageText}>{notification?.notificationMessage}</span>
				</>
			</ConditionalLink>
		</div>
	);
};

export default NotificationBar;
