import { NextLink } from 'components/next-link';

interface ConditionalLinkProps {
	children: React.ReactNode;
	href: string;
	passHref: boolean;
	condition: boolean;
}

export const ConditionalLink = (props: ConditionalLinkProps): React.JSX.Element =>
	!!props.condition && props.href ? (
		<NextLink href={props.href} passHref={props.passHref}>
			{props.children}
		</NextLink>
	) : (
		<a href={props.href}>{props.children}</a>
	);

export default ConditionalLink;
